<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
        <TaskEditEl :typeItems="typeItems" @submit="TaskEditSubmit"/>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import TaskEditEl from './module/taskEdit.vue'
import { inject, onBeforeMount, provide, ref } from 'vue'
import { list, TaskDel, TaskTypeList } from '@/api/task'


export default {
    components: {ATable, TaskEditEl},
    setup () {
        const tableUrl = ref(list)
        const elplus = inject('elplus')
        const columns = ref([
            {prop: 'title', label: '任务标题'},
            {prop: 'icon', label: '任务图标', type: 'image'},
            {prop: 'exp', label: '任务经验'},
            {prop: 'fishCount', label: '小鱼干数量'},
            {prop: 'financialCount', label: '财商币数量'},
            {prop: 'cardName', label: '卡片'},
            {prop: 'period', label: '任务周期', render (text) {
                let label = {1: '天任务', 2: '周任务'}
                return label[text] || ''
            }},
            {prop: 'type', label: '任务周期', render (text) {
                // let label = {1: '登录', 2: '出行一次', 3: '答题pk一次', 4: '收获小鱼干', 5: '分享游戏'}
                let [item = {}] = typeItems.value.filter(item => item.id == text)
                return item.name || ''
            }},

            {prop: 'appTask', label: '小程序任务', render (text) {
                let label = {1: '是', 0: '否'}
                return label[text] || ''
            }},
            {prop: 'operate', label: '操作', type: 'operate', width: 140, button: [
                {label: '编辑', dispose: 'Editor'},
                {label: '删除', dispose: 'TaskDelete'}
            ]},
        ])
        
        const searchFormItems = ref([
            {label: '标题', prop: 'title', type: 'input'},
            {label: '类型', prop: 'type', type: 'select', options: [{label: '天任务', value: 1}, {label: '周任务', value: 2}]},
        ])


        let editorForm = ref({})
        let taskVisible = ref(false)
        let table = ref(null)
        let typeItems = ref([])
        provide('taskVisible', taskVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                    console.log(row)
                if (row.id) {
                    editorForm.value = {
                        title: row.title, 
                        icon: row.icon, 
                        exp: row.exp, 
                        fishCount: row.fishCount, 
                        financialCount: row.financialCount, 
                        cardId: row.cardId, 
                        cardName: row.cardName,
                        type: row.type ? Number(row.type) : '', 
                        period: row.period ? Number(row.period) : '',
                        id: row.id,
                        appTask: row.appTask
                    }
                } else {
                    editorForm.value = {title: '', icon: '', exp: '', fishCount: '', financialCount: '', cardId: '', type: '', period: '', appTask: 1}
                }
                taskVisible.value = !taskVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            TaskDelete (row) {
                elplus.confirm(`确定删除该任务吗？此操作不可逆！！！`).then(() => {
                    let loading = elplus.loading()
                    TaskDel({id: row.id}).then(res => {
                        loading.close()
                        if (!res.ret) methods.TaskEditSubmit()
                    })
                })
            },
            TaskEditSubmit () {
                taskVisible.value = false
                table.value.SearchSubmit()
            }
        }

        onBeforeMount(() => {
            TaskTypeList({}).then(res => {
                console.log(res)
                if (!res.ret) {
                    typeItems.value = res.data
                }
            })
        })



        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            typeItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>